import React from 'react'
import {
    Layout,
    SEO,
    FullHeightWrapper,
    Card,
    NavLink
  } from '../components/elements';

export default function PourquoiConsodurable(){
    return(
        <FullHeightWrapper>
            <SEO 
                title="Pourquoi condurable.ch ?" 
                description="L'annuaire étique et reponsable des entreprises de Suisse romande, soutenez les acteurs locaux" 
                keywords={["annuaire", "étique", "responsable", "durable", "suisse", "romande", "alimentation", "hospitalité"]} 
            />
            <NavLink to="/" />
            <Card title="Pourquoi condurable.ch ?">
            Ce site a été créer pour mettre en lumière tous les acteurs du développement durable en Romandie. 
            Il regroupe plusieurs secteurs qui sont mentionnés dans la page d’accueil. Le but de cette action est de faire en sorte que vous, consommateurs-trices, 
            vous preniez ce site comme référence pour changer vos habitudes et permettre à notre belle région un développement durable et plus respectueux de tout 
            ce qui peut l’être.
            </Card>
            <Card>
            <p className="citation">
                « un <b>développement</b> qui répond aux besoins du présent sans compromettre la capacité des générations futures à répondre aux leurs »
                <span className="author">Harlem Brundtland, premier Ministre norvégien (1987)</span>
            </p>
            </Card>
        </FullHeightWrapper>
    )
}